<template>
  <v-expansion-panel class="mb-2">
    <v-expansion-panel-header>
      <span><v-icon class="mr-1" small>mdi-cog</v-icon> Configurações</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters>
        <v-col cols="4">
          <v-card-text>
            <label class="subheading">Versão:</label>
            <v-text-field v-model="processingConfig.version" hint="Clientes PDV e Híbridos devem ter versão 3.0 ou acima" :disabled="!editable" outlined />
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-card-text>
            <label class="subheading">Encoding:</label>
            <v-text-field v-model="processingConfig.encoding" :disabled="!editable" hide-details outlined />
          </v-card-text>
        </v-col>
        <v-col cols="2">
          <v-card-text class="mt-3 ml-12">
            <label class="subheading">Unificado:</label>
            <v-switch v-model="processingConfig.unified" :disabled="!editable" class="mx-auto" value />
          </v-card-text>
        </v-col>
        <v-col cols="2">
          <v-card-text class="mt-3 ml-12">
            <label class="subheading">Bloated:</label>
            <v-switch v-model="processingConfig.bloated" :disabled="!editable" class="mx-auto" value />
          </v-card-text>
        </v-col>
        <btn-card-actions class="pt-0 mt-0" :editable.sync="editable" @cancel="$emit('cancel')" @save="saveManager" />
      </v-row>

      <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="save" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { MUTATION_UPDATE_PROCESSING_CONFIGURATION } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: String,
    processingConfig: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    },
    integrationType: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    editable: false,
    changesDialog: false,
    changesMessage: ''
  }),
  methods: {
    async save() {
      if (this.integrationType !== 'backoffice' && parseFloat(this.processingConfig.version) < 3.0)
        return this.$snackbar({ message: 'Versão incorreta para o tipo de integração do cliente', snackbarColor: '#F44336' })

      this.$setLogMessage(this.changesMessage)
      const variables = {
        id: this.accountId,
        unified: this.processingConfig.unified,
        bloated: this.processingConfig.bloated,
        version: this.processingConfig.version,
        encoding: this.processingConfig.encoding
      }

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_PROCESSING_CONFIGURATION,
          variables
        })

        this.$snackbar({ message: 'Configurações atualizadas com sucesso', snackbarColor: '#2E7D32' })
        this.editable = false
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao atualizar configurações', snackbarColor: '#F44336' })
      }
    },
    saveManager() {
      if (this.isDeploying) return this.save()
      this.changesDialog = true
    }
  }
}
</script>
